<!-- 用户充值 -->
<template>
  <div class="userrecharge">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='充值'></logotitle>
      </el-col>
      <el-col :span="12">
        <rechargestep class='rechargestep' :steps='0'></rechargestep>
      </el-col>
    </el-row>
    <usercentertitle title='填写充值金额' :types='1'></usercentertitle>
    <div class="rechargeCnt">
      <div class="group-re">
        <div class="lt">
          充值账户：
        </div>
        <div class="rt">
          {{this.$store.state.user.ContactName}}
        </div>
      </div>
      <div class="group-re">
        <div class="lt">
          充值金额：
        </div>
        <div class="rt">
          <div class="ipt">
            <input type="number" v-model='price'  placeholder="0"  >元
          </div>
          <span class="error" v-if='error.price'>{{error.price}}</span>
        </div>
      </div>
      <div class="group-re">
        <div class="lt">
        </div>
        <div class="rt">
          <button class="next" @click='nexts' :disabled="disabled">下一步</button>
          <button class="back" @click='backlink'>上一步</button>
        </div>
      </div>
      <div class="reinfo">
        <h6><span>!</span>温馨提示：</h6>
        <p>1.充值成功后，预存余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；</p>
        <p>1.充值金额输入值必须是不小于10且不大于50000的正整数；</p>
        <p>3.充值完成后，您可以进入账户充值记录页面进行查看预存余额充值状态；</p>
      </div>
    </div>
  </div>
</template>
<script>
import rechargestep from '@/components/rechargestep/rechargestep.vue';//充值步骤
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
export default {
  name: 'userrecharge',
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'充值-微微定',
      price:null,
      t:0,
      error:{
        price:null
      },
      disabled:false
    }
  },
  components: {
    rechargestep,
    logotitle,
    usercentertitle
  },
  methods:{
    backlink(){
      // 返回上一步
      this.$router.go(-1)
    },
    nexts(){
      // 下一步
      // 调用退款
      this.error.price =null
      if(!this.price){
       return this.error.price ='请填写充值金额'
      }
      this.disabled = true
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/DepositCustomer?money='+this.price,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.disabled = false
              this._router({then:()=>{
                this.$router.push({path:'/rechargemethod?money='+this.price+'&orderNumber='+res.data.Data.orderNumber+'.html'})
              },exp:true,catch:()=>{
                this.$router.push({path:'/accountlogin',query:{redirect:'/rechargemethod?money='+this.price+'&orderNumber='+res.data.Data.orderNumber+'.html'}})
                // this._open({path: "/accountlogin",query: {redirect:'/'}})
              }})
            }else{
              this.disabled = false
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
    'price'(n,o){
      // var regEx = /^[0-9]\d*$/;
      // if(n&&!regEx.test(n)){
      //   this.price = o
      // }
      // clearTimeout(this.t)
      // this.t = setTimeout(()=>{
      //   if(n<10&&n>0){
      //     this.price =10
      //   }
      //   if(n>50000){
      //     this.price = 50000
      //   }
      // },300)
    }
  }
}
</script>
<style  lang="scss" scoped>
.userrecharge {
  background: #fff;padding-bottom:170px;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;
    .rechargestep {
      float:right;margin-top:5px;
    }
  }
  .rechargeCnt {
    width: 1198px;border:1px solid #dddddc;margin: 0 auto;padding-top:25px;padding-bottom:30px;
    .group-re {
      zoom:1;padding-left:67px;margin-bottom:25px;
      .lt {
        float:left;font-size: 14px;color:#333;
      }
      .rt {
        float:left;font-size: 14px;position: relative;
        .ipt {
          input {
            width: 100px;height: 33px;border:1px solid #dddddc;margin-top:-25px;padding-left:10px;margin-right: 10px;line-height: 35px;font-size: 16px;font-weight: bold;
            &:focus {
              border:1px solid #fd6873;color:#fd6873;
              &::placeholder {
                color:#fd6873;
              }
            }
          }
        }
        .next {
          width: 136px;height: 38px;background: #fd6873;border-radius: 5px;color:#fff;margin-right: 25px;line-height: 38px;margin-left:70px;
          &:hover {
            opacity: 0.8;
          }
        }
        .back {
          width: 136px;height: 38px;border-radius: 5px;border:1px solid #bbbbbb;background: none;line-height: 36px;
          &:hover {
            opacity: 0.8;
          }
        }
        .error {
          position: absolute;top:28px;font-size: 12px;color:#fd6873;left:0px;
        }
      }
      &:after {
        content: "";display: block;clear:both;
      }
    }
    .reinfo {
      width: 1180px;height: 125px;margin:0 auto;background: #f9fafd;border-radius: 15px;margin-top:35px;
      h6 {
        padding-left:58px;font-size: 14px;padding-top:20px;margin-bottom:7px;
        span {
          display: inline-block;width: 15px;height: 15px;background: #ffa31d;line-height: 15px;text-align: center;font-size: 12px;color:#fff;border-radius: 50%;margin-right: 5px;vertical-align: 1px;
        }
      }
      p {
        padding-left:58px;font-size: 14px;color:#666666;margin-bottom:3px;
      }
    }
  }
}
</style>
